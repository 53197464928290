p{
    letter-spacing: 0!important;
}
.title-block{
    padding:.2em .5em;
    h2{
        font-size:1.3em;
    }
}

.recruit2{
    .wp-block-button__link{
        border-radius: 5px;
        background-color: #dedede;
        padding: calc(0.3em + 2px) calc(0.9em + 2px);
        font-size:0.8em;
        &::after{
            content: "▶";
            font-size:.6rem;
            margin-left:1em;
            position: relative;
            top:-2px;
            color:#555;
        }
    }
    p{
        font-size:0.8rem;
    }
    &__first{
        padding-left:3em;
        @media screen and (max-width:750px) {
            padding-left:3vw;
        }
        p{
            span{
                position: relative;
                margin-left:-5px;
            }
            &:nth-child(1){
                font-size: 3rem;
                line-height: 1.4;
                margin-top:60px;
                @media screen and (max-width:544px) {
                    font-size:2rem;
                }
            }
            &:nth-child(2){
                font-size: 1rem;
                line-height: 1.6;
                margin-top:20px;
                @media screen and (max-width:544px) {
                  font-size:.8rem;
                }
            }
            &:nth-child(3){
                font-size: 1rem;
                line-height: 1.6;
                margin-bottom:60px;
                margin-top:20px;
                @media screen and (max-width:544px) {
                    font-size:.66rem;
                  }
            }
        }
    }
    &__occupation{
        h3{
            margin-bottom:0.4em;
            &::after{
                display: block;
                content: " ";
                width: 100%;
                height:1px;
                background-color: #555;
                position: relative;
                top:5px;
            }
        }
    }
    &__article{
        background-color:#ECECEC;
        border-radius: 5px;
        margin:.5em 0;
        
    }
    &__article-list{
        margin-top:1.4em;
    }
    &__content{
        padding:0;
        height:0;
        overflow: hidden;
        .has-medium-font-size{
            line-height:1.4;
        }
        h5{
            margin-bottom:1rem;
            width: 100%;
            font-size:1em;
            
        }
        h6{
            margin:1em 0 .5em 0;
            font-size:0.9rem;
            &::before{
                content:"●";
                color:#a3a3a3;
                margin-right:.3em;
            }
        }
        ul{
            padding-left:2.2em;
            list-style-type: disc;
        }
        p{
            margin-bottom:1em;
            line-height:1.4;
        }

        em{
            color:#BF2D28;
            margin-right:5px;
            font-family: "calluna", serif;
            font-weight: 400;
            font-style: italic;
        }
    }
    
    &__article-title{
        background-color: #DEDEDE;
        border-radius: 5px;
        padding:.5em 1em;
        position: relative;
    }

    &__article-title-content{
        gap:0;
        p{
            font-size:.8rem;
            padding:0 30px 0 0;
            color: #555;
            line-height: 1.4;
        }
        h4{
            padding:0 30px 0 0;
            font-size: 1.3rem;
            @media screen and (max-width:600px) {
                font-size: 1rem;
                padding:0;
            }
        }
    }
    &__overview{
        h3{
            margin-bottom:1em;
            font-size:1.5em;
            &::after{
                display: block;
                content: " ";
                width: 100px;
                height:1px;
                background-color: #555;
                position: relative;
                top:5px;
            }
        }
        ul{
            list-style-type: disc;
            padding-left:25px;
            margin:0 0 1em 0;
            li{
                font-size:1rem;
                margin-bottom:1em;
            }
        }
        a{
            text-decoration: none;
            color:#000;
        }
        .wp-block-button__link{
            border-radius: 5px;
            background-color: #dedede;
            padding: calc(0.3em + 2px) calc(0.9em + 2px);
            &::after{
                content: "▶";
                font-size:.6rem;
                margin-left:1em;
                position: relative;
                top:-2px;
                color:#555;
            }
        }
    }
    &__toggle-button{
        position: absolute;
        right:5px;
        width: 50px;
        height:50px;
        &::before{
            content: " ";
            display: block;
            width: 20px;
            height:3px;
            background-color: #555;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
        }
        &::after{
            content: " ";
            display: block;
            width: 20px;
            height:3px;
            background-color: #555;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }
    &__form{
        p{
            margin-top:30px;
            margin-bottom:20px;
        }
    }
    &__form-table{
        background-color: #EEE7E7;
        border-radius: 5px;
        padding:2em 3em 0em 3em;
        margin:1em 0;
        @media screen and (max-width:600px) {
            padding:2em 1em 0em 1em;
        }

        .wpcf7 {
            margin:0;
        }
        p{
            margin:0;
        }
        
        form{
            label{
                margin:.5em 0 .2em 0;
            }
            input{
                margin:0;
                width: 100%;
            }
            input[type="submit"]{
                background: linear-gradient(180deg, #76A963 11.98%, #719565 100%);
                max-width: 300px;
                position: relative;
                left:50%;
                transform: translate(-50%,0);
                margin-top:1em;
                border-radius: 5px;

            }
            select{
                padding:.3em 1em;
                border-radius: 5px;
            }

            textarea{
                margin:0;
                padding:.3em 1em;
                width:100%;
                height:150px;
            }
            .codedropz-upload-handler{
                margin-bottom:0;
            }
        }
    }

    &__infomation{
        padding:2em .5em;
        gap:6em;
        @media screen and (max-width:600px) {
            flex-wrap: wrap!important;
            gap:2em;
        }
    }
    &__access{
        padding:2em .5em;
    }
}

.open.recruit2{
    &__article{

    }
    &__content{
        height:initial;
        padding:1.4em 1.4em 1em 1.4em;
    }
    &__toggle-button{
        &::after{
            display: none;
        }
    }
}

