#anv40{
    top:0;
    left:0;
    position: fixed;
    z-index: 9999;
    height:100vh;
    width: 100%;
    padding:1vw;
    background-color: #000000c8;
    animation:anv40 1s ease forwards;
    pointer-events: none;
    &__wrap{
        position: relative;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height:calc(100% - 1vh);
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        animation:anv40-wrap 1s 1s ease forwards;
        opacity: 0;
        @keyframes anv40-wrap {
            0%{
                opacity: 0;
                top:calc(50% + 50px);
            }
            100%{
                opacity: 1;
                pointer-events:all;
                top:50%;
            }
        }
    }
    &__iframe{
        width: 100%;
        height:100%;
        border:0;
        background-color: #f0f0f0;
        &::-webkit-scrollbar {
            width: 10px; /* スクロールバーの幅を調整 */
        }
        &::-webkit-scrollbar-track{

        }
        &::-webkit-scrollbar-thumb{
            
        }
    }
}

.anv-open{
    animation:anv40 1s ease forwards;
    pointer-events: none;
    @keyframes anv40 {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
            pointer-events:all;
        }
    }
}

.red-cross{
    position: absolute;
    width:50px;
    right:30px;
    top:30px;
    cursor: pointer;
    
}

.anv40__crose{
    display: none;
}

.anv40__open{
    display: block;
}